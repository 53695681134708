<template>
  <section>
    <disciplinaLista/>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import disciplinaLista from "@/components/disciplinas/disciplinaAll.vue"

export default defineComponent({
  components:{
    disciplinaLista
  },
  data() {
    return {
    };
  },
});
</script>
